$link-color:                 #0078ff !default;
$link-hover-color:           #ff033d !default;

/*zoom*/
.zoom{
  position: absolute;
  top: 22px;
  left: 22px;
  width: 40px;
  z-index: 11;

  &__btn{
    width: 40px;
    height: 41px;
    border-radius: 4px 4px 0 0;
    border: 1px solid #cccccc;
    position: relative;
    background: #fff;
    color: $link-color;
    cursor: pointer;
    &:before,
    &:after{
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      background: $link-color;
      transition: background 0.3s;
    }
    &:before{
      width: 18px;
      height: 4px;
      margin-top: -2px;
      margin-left: -9px;
    }
    & + &{
      margin-top: -1px;
      border-radius: 0 0 4px 4px;
    }
    &:hover{
      z-index: 1;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);

    }
    &:hover:before{
      background: $link-hover-color;
    }
    &:hover:after{
      background: $link-hover-color;
    }
    &_minus{

    }
    &_plus{
      &:after{
        width: 4px;
        height: 18px;
        margin-top: -9px;
        margin-left: -2px;
      }
    }

    &.disabled {
        cursor: default;
        &:before, &:after {
            background: #ccc;
        }
    }
  }
}
/*schema*/

.schema-holder{
  &__schema-block{
    position: relative;
    padding: 12px;
    z-index: 1;
  }
}
.schema-container{
  background: #f4f4f4;
  svg{
    display: block;
  }
}

.scrollbar-inner{
  position: relative;
  & > .scroll-element{
    background: #fff;
    &.scroll-y{
      right: 0;
      width: 15px;
      .scroll-element_outer{
        width: 10px;
        margin-left: 5px;
      }
      &.scroll-scrollx_visible{
        .scroll-element_size{
          top: -15px;
        }
      }
    }
    &.scroll-x{
      bottom: 0;
      height: 15px;
      .scroll-element_outer{
        height: 10px;
        margin-top: 5px;
      }
      &.scroll-scrolly_visible{
        .scroll-element_size{
          left: -15px;
        }
      }
    }
    .scroll-element_outer{
      border-radius: 10px;
    }
    .scroll-element_track{
      background: #fff;
    }
    .scroll-bar{
      background: #c9c9c9;
      border-radius: 10px;
      opacity: 1;
    }
  }
}


.schema-label {
  position: absolute;
  color: #333;
  display: none;
  z-index: 9;
  background: white;
  text-align: center;
  border-radius: 9px;
  font-size: 14px;
  line-height: 18px;
  width: 200px;
  box-shadow: 0 2px 15px rgba(0,0,0,0.35);
  padding: 18px 10px 14px;
  &__text{
    font-size: 14px;
    line-height: 18px;
    margin: 0 0 13px;
  }
  &__sector{
    display: block;
    text-transform: lowercase;
    &:first-letter{
      text-transform: uppercase;
    }
  }
  &__price{
    font-size: 18px;
  }
    .tickets-controls__alarm {
        display: inline-block;
        margin: 10px 0 5px;
        &:after {
            display: none;
        }
    }
}
.tickets-controls__alarm {
    border-radius: 3px;
    color: #fff;
    padding: 7px 13px 6px 45px;
    text-align: center;
    font-size: 13px;
    line-height: 14px;
    background: #ff033d url("../img/official/alarm-tickets-max.png") no-repeat 9px 8px;
    span {
        white-space: nowrap;
        display: block;
    }
    &:after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        right: -5px;
        top: 5px;
        border-style: solid;
        border-width: 6px 6px 0 6px;
        border-color: #ff033d transparent;
    }
}
